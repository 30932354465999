import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash/get"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import SongSearch from "../components/SongSearch"

const BlogIndex = props => {
  const posts = props.data.allMarkdownRemark.edges

  return (
    <Layout page="songs" location={props.location}>
      <SEO title="Blog" />
      <div id="songList" className="block-start">
        <div className="searchBar">
          <div className="container">
            <SongSearch />
          </div>
        </div>
        <div className="container">
          <div className="post_list">
            {posts.map(({ node }) => {
              const title = get(node, "frontmatter.title") || node.fields.slug
              return (
                <div className="post_item" key={node.fields.slug}>
                  <Link to={node.fields.slug}>
                    <div className="blog_ttl_ts">
                      <h2 className="blog_ttl">{title}</h2>
                    </div>
                    <div className="post_desc">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.spoiler,
                        }}
                      />
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            # date(formatString: "DD MMMM, YYYY")
            title
            spoiler
          }
        }
      }
    }
  }
`
