import React from "react"
import { IoIosSearch } from "react-icons/io"

const SongSearch = () => {
  return (
    <div id="SongSearch">
      <div className="search_group">
        <input type="text" placeholder="Search Songs Here...:)" />
        <IoIosSearch />
      </div>
    </div>
  )
}

export default SongSearch
